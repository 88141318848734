import Home from "./pages/home/Home";
import About from "./pages/about/About";
import WhyUs from "./pages/whyUs/WhyUs";
import Imprint from "./pages/imprint/Imprint";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import TOS from "./pages/termsofservice/TOS";

const App = () => {
  return (
    <div className="content">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/our-value-proposition" element={<WhyUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TOS />} />
          <Route path="/imprint" element={<Imprint />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
