import "./Home.css";
import axios from "axios";
import { useEffect, useState } from "react";
import plate from "./../../assets/plate.png";
import namaste from "./../../assets/namaste.svg";
import { Link } from "react-router-dom";
import React, { useCallback, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { IoIosCloseCircleOutline } from "react-icons/io";
import autoAnimate from "@formkit/auto-animate";
import { AxiosError } from "axios";
import { ThreeDots } from "react-loader-spinner";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

const Home = () => {
  const [email, setEmail] = useState("");
  const [successFlag, setSuccessFlag] = useState(false);
  const [dangerFlag, setDangerFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fireBtn = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_NODE_PATH, {
        email,
      });
      fireBtn();
      console.log(response.data.message);
      setEmail("");
      setDangerFlag(false);
      setSuccessFlag(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error?.response) {
        setErrormsg("No Server Response");
      } else if (error?.code === AxiosError.ERR_NETWORK) {
        setErrormsg("Network Error");
      } else if (error.response?.status === 404) {
        setErrormsg("404 - Not Found");
      } else if (error?.code) {
        setErrormsg(error.response.data.message);
      } else {
        setErrormsg("Oops, something went wrong!");
      }
      setSuccessFlag(false);
      setDangerFlag(true);
    }
  };

  return (
    <div className="wrapper">
      <div className="heroSection">
        <div className="text">
          <img className="namaste" src={namaste} alt="" />

          <p>
            Welcome to <b>DesiKhana</b>, your go-to destination for
            <b> authentic, healthy and affordable vegetarian</b> Indian tiffin
            service in
            <b> Munich</b>. We are currently setting up our operations. To
            receive updates about our launch, please submit your email ID below
          </p>

          <div className="notification" ref={parent}>
            {successFlag && (
              <div className="message success">
                <span>
                  Your email was successfully added to our mailing list. Thank
                  you for signing up!
                </span>
                <IoIosCloseCircleOutline
                  className="closeIcon"
                  onClick={() => setSuccessFlag(false)}
                />
              </div>
            )}

            {dangerFlag && (
              <div className="message danger">
                <span>{errormsg}</span>
                <IoIosCloseCircleOutline
                  className="closeIcon"
                  onClick={() => setDangerFlag(false)}
                />
              </div>
            )}
          </div>

          <div className="emailForm">
            <form onSubmit={handleSubmit}>
              <div className="inputs">
                <input
                  type="email"
                  placeholder="email@domain.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="legal">
                  <input type="checkbox" id="Ts&Cs" required="required" />
                  <label htmlFor="Ts&Cs">
                    I have read & agree to DesiKhana's{" "}
                    <Link to="/terms-of-service">Terms of Service</Link>
                    &nbsp;&&nbsp;
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </label>
                </div>
              </div>
              <button type="submit">
                <ThreeDots
                  height="1.5rem"
                  width="4rem"
                  radius="6"
                  color="#ffffff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={loading ? true : false}
                />
                <span className={loading ? "btn-loading" : "btn-txt"}>
                  Receive updates
                </span>
              </button>

              <ReactCanvasConfetti
                refConfetti={getInstance}
                style={canvasStyles}
              />
            </form>
          </div>
        </div>

        <div className="heroImage">
          <img src={plate} className="rotate" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
