import "./WhyUs.css";
import tiffin from "../../assets/tiffin.png";

const tiffinService = [
  {
    key: "Authenticity",
    value:
      "At DesiKhana, we take pride in preserving the traditional recipes handed down through generations, offering a taste of India's diverse culture and culinary heritage.",
  },
  {
    key: "Convenience",
    value:
      "With our tiffin delivery service, you can have delicious and healthy Indian meals delivered right to your door, saving you the time and effort of cooking or going out to eat.",
  },
  {
    key: "Variety",
    value:
      "Our rotating menu offers a variety of vegetarian Indian dishes, allowing you to try new flavors and recipes on a regular basis.",
  },
  {
    key: "Health",
    value:
      "Our meals are made with fresh, locally-sourced ingredients and are cooked in small batches, ensuring maximum flavor and nutritional value.",
  },
  {
    key: "Environmentally friendly",
    value:
      "Our tiffins are made of stainless steel and are reusable, reducing waste and being environmentally friendly.",
  },
  {
    key: "Cost-effective",
    value:
      "Subscribing to our tiffin service can be more cost-effective than eating out or ordering takeout on a regular basis.",
  },
  {
    key: "Support local business",
    value:
      "By subscribing to our tiffin service, you are supporting a local, family-owned business that is committed to providing delicious, healthy, and convenient vegetarian meals.",
  },
];

const WhyUs = () => {
  return (
    <div className="valuePropContainer">
      <div className="valueProp">
        <div className="text">
          <ol>
            {tiffinService.map((benefit, i) => {
              return (
                <li key={i}>
                  <b>{benefit.key}</b> <br /> {benefit.value}
                </li>
              );
            })}
          </ol>
        </div>
        <div className="tiffinImg">
          <img src={tiffin} alt="" />
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
