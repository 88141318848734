import "./About.css";
import dabbahero from "../../assets/dabbahero.png";

const About = () => {
  return (
    <div className="aboutUsContainer">
      <div className="aboutUs">
        <div>
          <div className="aboutUsText">
            <p>
              We are a family-owned business aimed at serving delicious,
              healthy, and convenient vegetarian Indian meals to our customers.
              We offer a rotating menu of delicious and nutritious vegetarian
              dishes, made with fresh, locally-sourced ingredients. Our meals
              are cooked in small batches to ensure maximum flavor and quality.
            </p>
            <p>
              Our startup was born out of a desire to share the essence of
              Indian cuisine with the diverse community of Munich, offering a
              delightful experience that transports you to the heart of India's
              culinary heritage. Our mission is to make authentic Indian cuisine
              accessible to everyone, while also promoting the benefits of a
              plant-based diet.
            </p>
            <p>
              Our tiffins are based on the concept of traditional Indian lunch
              boxes, which are made of stainless steel and are environmentally
              friendly and reusable. We deliver the tiffins to your door step
              daily, enabling you to focus your precious and limited hours of
              the day on doing things that matter to you the most.
            </p>
            <p>
              Our team is made up of experienced chefs who have a passion for
              creating delicious and authentic Indian dishes. We take pride in
              using traditional cooking techniques and using freshly ground
              spices to bring out the best flavors in our food.
            </p>
            <p>
              We are committed to providing our customers with the highest
              quality food and service. We believe that everyone should have
              access to delicious, healthy, and convenient vegetarian meals, and
              we are dedicated to making that a reality.
            </p>
            <div className="dabbawalaImg">
              <img src={dabbahero} className="fadeInUpBig" alt="" />
            </div>
            <p>
              <b>
                Thank you very much for choosing us. We look forward to serving
                you and your taste buds!
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
