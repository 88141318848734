import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="wrapperPrivacyPolicy">
      <div className="mainContentPrivacyPolicy">
        <div className="item">
          <h2>Privacy Policy</h2>
          <p>
            This Privacy Policy describes how DesiKhana (&quot;we&quot; or
            &quot;us&quot;) collects, uses, and protects the personal data of
            individuals (&quot;users&quot; or &quot;you&quot;) who visit the
            website{" "}
            <a href="http://www.desikhana.de/" target="_new">
              www.desikhana.de
            </a>{" "}
            (&quot;website&quot;) and provide their personal information through
            the sign-up form or contact form. We are committed to protecting
            your privacy and complying with applicable data protection laws,
            including the General Data Protection Regulation (GDPR).
          </p>
        </div>
        <div className="item">
          <p>
            <strong>1. Information We Collect</strong>
          </p>
          <p>
            We collect the following types of personal data when you voluntarily
            provide it to us:
          </p>
          <ol>
            <li>Email addresses</li>
            <li>Addresses</li>
            <li>Phone numbers</li>
          </ol>
        </div>
        <div className="item">
          <p>
            <strong>2. How We Collect Personal Data</strong>
          </p>
          <p>
            We collect personal data when you provide it to us through the
            sign-up form or contact form on our website.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>3. Use of Personal Data</strong>
          </p>
          <p>We use the personal data you provide to:</p>
          <ol>
            <li>Provide information about our products and services</li>
            <li>Communicate with you regarding your inquiries or requests</li>
          </ol>
        </div>
        <div className="item">
          <p>
            <strong>4. Third-Party Disclosure</strong>
          </p>
          <p>We do not share your personal data with any third parties.</p>
        </div>
        <div className="item">
          <p>
            <strong>5. Data Retention</strong>
          </p>
          <p>
            We retain your personal data for a period of up to 2 years after the
            last user action or until you request its deletion.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>6. Data Security</strong>
          </p>
          <p>
            We implement reasonable security measures to protect your personal
            data. Your information is stored on a secure database hosted with a
            leading cloud service provider.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>7. Cookies</strong>
          </p>
          <p>
            We do not use cookies or similar tracking technologies on our
            website.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>8. International Data Transfers</strong>
          </p>
          <p>
            We do not transfer your personal data outside of the European
            Economic Area (EEA).
          </p>
        </div>
        <div className="item">
          <p>
            <strong>9. Children&apos;s Privacy</strong>
          </p>
          <p>
            Our services are not targeted at children under the age of 16. We do
            not knowingly collect personal data from children. If you are a
            parent or guardian and believe that your child has provided personal
            data to us, please contact us to request deletion of their
            information.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>10. Your Rights</strong>
          </p>

          <p>You have the following rights regarding your personal data:</p>
          <ol>
            <li>
              Right to access: You can request access to the personal data we
              hold about you.
            </li>
            <li>
              Right to rectification: You can request correction of inaccurate
              or incomplete personal data.
            </li>
            <li>
              Right to erasure: You can request the deletion of your personal
              data.
            </li>
            <li>
              Right to object: You can object to the processing of your personal
              data.
            </li>
            <li>
              Right to restrict processing: You can request restriction of the
              processing of your personal data.
            </li>
            <li>
              Right to data portability: You can request a copy of your personal
              data in a structured, machine-readable format.
            </li>
          </ol>
        </div>
        <div className="item">
          <p>
            <strong>11. Contact Us</strong>
          </p>

          <p>
            If you have any questions or concerns about our privacy practices or
            your personal data, please contact us at{" "}
            <a href="mailto:support@desikhana.de" target="_new">
              support@desikhana.de
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
