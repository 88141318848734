import "./Imprint.css";

const Imprint = () => {
  return (
    <div className="imprintWrapper">
      <div className="imprintSection">
        <h1>Impressum</h1>
        <h2>Angaben gemäß § 5 TMG:</h2>
        <p>
          <br />
          Siddhant Jain
        </p>
        <h3>Postanschrift:</h3>
        <p>
          Oberbiberger Straße 11
          <br />
          München
          <br />
        </p>
        <h3>Kontakt:</h3>
        <p>
          Telefon: +49 171 4865472
          <br />
          E-Mail: siddhant@desikhana.de
        </p>
        <p>
          <br />
        </p>
        <h2>Hinweise zur Website</h2>
        <h2>Information gemäß § 36 VSBG</h2>
        <p>
          Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die
          alternative Streitbeilegung in Verbrauchersachen) erklärt der
          Betreiber dieser Website:
        </p>
        <p>
          Wir sind bereit, an Streitbeilegungsverfahren bei der folgenden
          Verbraucherschlichtungsstelle teilzunehmen:
        </p>

        <h2>Hinweis</h2>
        <p>
          Die Plattform der EU zur außergerichtlichen Streitbeilegung finden Sie
          online unter:{" "}
          <a href="https://ec.europa.eu/consumers/odr/">
            https://ec.europa.eu/consumers/odr/
          </a>
        </p>
      </div>
    </div>
  );
};

export default Imprint;
