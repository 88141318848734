import { Link } from "react-router-dom";
import "./footer.css";
import { FiInstagram } from "react-icons/fi";
import { BsFacebook } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        &copy; DesiKhana {new Date().getFullYear()} |&nbsp;
        <Link to="/imprint">Imprint</Link>&nbsp;|&nbsp;
        <a href="mailto:support@desikhana.de?subject=Hello">Contact Us</a>
      </div>
      <div className="socialMediaIcons">
        <div>
          <a
            href="https://www.instagram.com/desikhana.de"
            target="_blank"
            rel="noreferrer"
          >
            <FiInstagram />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/DesiKhanaDE/"
            target="_blank"
            rel="noreferrer"
          >
            <BsFacebook />
          </a>
        </div>
      </div>
      {/* <div>
        <a href="mailto:support@desikhana.de?subject=Hello">Contact Us</a>
      </div> */}
    </div>
  );
};

export default Footer;
