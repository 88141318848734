import "./TOS.css";

const TOS = () => {
  return (
    <div className="wrapperTOS">
      <div className="maincontentTOS">
        <div className="item">
          <h2>Terms of Use</h2>
          <p>
            By accessing and using the website{" "}
            <a href="http://www.desikhana.de/" target="_new">
              www.desikhana.de
            </a>{" "}
            (&quot;website&quot;), you agree to comply with the following terms
            and conditions:
          </p>
        </div>
        <div className="item">
          <p>
            <strong>1. Intellectual Property</strong>
          </p>
          <p>
            All content on the website, including text, images, graphics, logos,
            and trademarks, is the property of DesiKhana or its licensors and is
            protected by intellectual property laws. You may not use, reproduce,
            modify, or distribute any content from the website without our prior
            written permission.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>2. Website Use</strong>
          </p>
          <p>
            You agree to use the website for lawful purposes only and not to
            engage in any activity that may:
          </p>
          <ol>
            <li>Violate applicable laws, regulations, or third-party rights</li>
            <li>Interfere with the operation or security of the website</li>
            <li>Transmit viruses or other malicious code</li>
            <li>
              Collect or store personal data of others without their consent
            </li>
          </ol>
        </div>
        <div className="item">
          <p>
            <strong>3. Disclaimer</strong>
          </p>
          <p>
            The website and its content are provided on an &quot;as is&quot;
            basis without any warranties, express or implied. We make no
            representations or warranties regarding the accuracy, completeness,
            or reliability of the information on the website.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>4. Limitation of Liability</strong>
          </p>
          <p>
            To the extent permitted by law, we shall not be liable for any
            direct, indirect, incidental, consequential, or punitive damages
            arising out of or in connection with your use of the website.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>5. Links to Third-Party Websites</strong>
          </p>
          <p>
            The website may contain links to third-party websites. We are not
            responsible for the content or privacy practices of such websites.
            The inclusion of any link does not imply our endorsement or
            affiliation with the linked website.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>6. Amendments</strong>
          </p>
          <p>
            We may update or modify these terms of use at any time without prior
            notice. By continuing to use the website, you accept any revised
            terms.
          </p>
        </div>
        <div className="item">
          <p>
            <strong>7. Governing Law</strong>
          </p>
          <p>
            These terms of use are governed by and construed in accordance with
            the laws of Germany. Any disputes arising out of or in connection
            with these terms shall be subject to the exclusive jurisdiction of
            the courts of Germany.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TOS;
